@font-face {
	font-family: "s-icons";
	font-style: normal;
	font-weight: 700;
	font-display: swap;
	src: url(../fonts/stewardicons.ttf);
}

[class^="icon-"],
[class*=" icon-"] {
	/* use !important to prevent issues with browser extensions that change fonts */
	font-family: "s-icons" !important;
	font-style: normal;
	font-weight: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;

	/* Better Font Rendering =========== */
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

.icon-bills:before {
	content: "\e900";
}
.icon-chores:before {
	content: "\e901";
}
.icon-home:before {
	content: "\e902";
}
.icon-items:before {
	content: "\e903";
}
