#bar {
	height: 58px;
	background-color: var(--ion-card-background);

	ion-label {
		font-size: 100%;
	}

	.bar-icon {
		font-size: 22px;
		margin-bottom: 2px;
	}

	ion-tab-button[selected="true"] {
		ion-label {
			font-weight: 800;
			letter-spacing: -0.2px;
		}
	}
}
