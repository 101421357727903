html {
	font-size: 16px;
}

@import "./font.scss";
@import "./text.scss";
@import "./variables.scss";
@import "./layout.scss";
@import "./components.scss";
@import "./icons.scss";
