.swiper {
	width: 100% !important;
	height: 40% !important;
	flex: 1;
	.swiper-slide .column {
		display: flex;
		flex-direction: column;
		justify-content: center;
		align-items: center;
		width: 100%;
		height: 100%;
	}
	.swiper-button-prev,
	.swiper-button-next {
		color: rgb(90, 90, 90);
		font-size: 40%;
	}
}

img.s-img {
	max-height: 30vh;
	object-fit: cover;
}

.slider {
	height: 50vh !important;
	flex: 1;
	.alice-carousel,
	* {
		height: 100%;
	}
}

.slide {
	padding: 1em;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	width: 100%;
	height: 100%;
}
