h1,
h2,
h3,
h4,
h5,
h6,
p {
	padding: 0;
	margin: 0;
}

html,
body,
* {
	font-family: "DM Sans", -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Oxygen, Ubuntu, Cantarell,
		"Open Sans", "Helvetica Neue", sans-serif;
}

h1 {
	font-weight: 800;
	letter-spacing: -1.5px;
	text-align: left;
}

h3 {
	font-size: 1.3em;
	font-weight: 800;
	letter-spacing: -0.8px;
}

h4 {
	font-size: 1.15em;
	font-weight: 800;
	letter-spacing: -0.3px;
}

.alert-cta {
	font-weight: bold;
}

a {
	color: inherit;
	text-decoration: none;
}

.disabled {
	color: rgb(139, 139, 139) !important;
}

.small {
	font-size: 75% !important;
}

.medium {
	font-size: 85% !important;
}

.bold {
	font-weight: bold;
}

.left {
	text-align: left !important;
}

.center {
	width: 100%;
	text-align: center !important;
}

.right {
	text-align: right !important;
}

.blue {
	color: blue !important;
}

.red {
	color: red !important;
}

.huge {
	font-size: 3em;
}

.monospace {
	font-family: monospace !important;
}

.regular {
	font-weight: 400;
}

ul {
	padding: 1em 1em 0 !important;
	margin: 0 !important;
}

li {
	padding: 0;
	margin: 0;
}

hr, .hr {
	border: 1px solid rgb(129, 129, 129);
	height: 0;
	margin: 1em 1.5em 1em 1.5em;
}
