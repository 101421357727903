ion-button {
	box-shadow: var(--shadow);
	border-radius: 10px;
}

ion-button[fill="outlined"] {
	border: solid 2px var(--ion-color-primary);
	color: var(--ion-color-primary-shade);
	font-weight: bold;
}
