#main {
	ion-content {
		padding: 1em 4em;
		display: flex;
		flex-direction: column;
		align-items: center;
		text-align: center;
	}

	main {
		padding: 1em 2em;
	}
}

ion-toolbar#toolbar {
	display: flex;
	flex-direction: column-reverse;
	min-height: 5em;

	ion-title {
		font-size: 1.4em;
		font-weight: bold;
		letter-spacing: -1px;
	}
}
